import React from 'react'
import { graphql } from 'gatsby'
import DatoCMSModel from '../../models/DatoCMSModel'

import Icon from '@c/icon'
import Layout from '@c/layout'
import Breadcrumb from '@c/breadcrumb'
import PageHeader from '@c/page-header'

import Photos from '@v/culture/photos'
import Video from '@v/culture/video'
import OpenPositions from '@v/culture/open-positions'

const Page = ({ data }) => {
  const { page } = data
  const { locale, title } = page
  const [header = {}] = page.header
  const [video] = page.video
  const model = new DatoCMSModel(page)
  const jumpToOpenPositions = () => {
    if (typeof document !== 'undefined' && typeof window !== 'undefined') {
      const elem = document.getElementsByClassName('open-positions')[0]
      if (elem) {
        elem.scrollIntoView({
          behavior: 'smooth',
          block: 'start',
          inline: 'nearest'
        })
      }
    }
  }
  return (
    <Layout locale={locale} seo={{ ...model.getSeoComponentProps() }}>
      {/* BREADCRUMB */}
      <div className="container mb-60">
        <div className="row">
          <div className="col-12">
            <Breadcrumb parentPages={[]} currentPage={title} />
          </div>
        </div>
      </div>

      {/* header*/}
      <div className="container ta-center">
        <PageHeader topTitle={header.titleSmall} title={header.titleBig} />
        <div className="row mt-32">
          <div className="col-12 col-lg-6 offset-lg-3">
            <p>{header.description}</p>
          </div>
        </div>
        <div className="row mt-40">
          <div className="col-12">
            <button
              onClick={jumpToOpenPositions}
              className="btn btn--red btn--round"
            >
              {header.button1Text}
            </button>
            <div className="d-lg-none" />
            <button
              onClick={jumpToOpenPositions}
              className="btn btn--round mt-32 mt-lg-0 ml-lg-24"
            >
              {header.button2Text}
              <Icon className="ml-8" />
            </button>
          </div>
        </div>
      </div>

      <Photos photos={page.photos} />

      {video && <Video video={video} />}

      <OpenPositions
        titleSmall={page.openPositionsTitleSmall}
        titleBig={page.openPositionsTitleBig}
        description={page.openPositionsDescription}
        allCategoriesText={page.allOpenPositionCategoriesText}
        openPositionCategories={page.openPositionCategories}
        culturePageSlug={page.slug}
      />
    </Layout>
  )
}

export default Page

export const query = graphql`
  query CulturePageQuery($locale: String) {
    page: datoCmsCulturePage(locale: { eq: $locale }) {
      _allSlugLocales {
        locale
        value
      }
      metaTags {
        ...MetaTags
      }
      locale
      title
      slug

      header {
        titleSmall
        titleBig
        description
        button1Text
        button1Url
        button2Text
        button2Url
      }

      photos {
        title
        content
        image {
          alt
          asset {
            localImage {
              extension
              publicURL
              childImageSharp {
                gatsbyImageData(layout: FULL_WIDTH)
              }
            }
          }
        }
      }

      video {
        titleSmall
        titleBig
        description
        embedUrl
      }

      openPositionsTitleSmall
      openPositionsTitleBig
      openPositionsDescription
      allOpenPositionCategoriesText
      openPositionCategories {
        id
        title
        description
        positions {
          id
          locale
          slug
          title
        }
      }
    }
  }
`
