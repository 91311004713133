import React from 'react'

import PageHeader from '@c/page-header'

const Video = ({ video }) => {
  return (
    <div className="container mt-60 mt-lg-160 ta-center">
      <div className="row">
        <div className="col-12">
          <PageHeader
            topTitle={video.titleSmall}
            title={video.titleBig}
            type="h3"
          />
          <p className="mt-32">{video.description}</p>
        </div>
      </div>

      <div className="row justify-content-center mt-60">
        <div className="col-12 col-lg-8">
          <div className="responsive-video">
            <iframe
              className="responsive-video-content"
              title="hey"
              src={video.embedUrl}
              frameBorder="0"
              allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default Video
