import React from 'react'
import ProjectImage from '@/components/project-image'

const Photos = ({ photos }) => {
  const [photo1, photo2, photo3] = photos

  return (
    <div className="container mt-60 mt-lg-140">
      <div className="row align-items-lg-center">
        {/* left */}
        <div className="col-12 col-lg-7">
          <div className="row justify-content-lg-end">
            {/* 1 */}
            {photo1 && (
              <div className="col-12">
                <figure>
                  <ProjectImage image={photo1.image} />
                </figure>
                <h4 className="mt-24">{photo1.title}</h4>
                <div dangerouslySetInnerHTML={{ __html: photo1.content }} />
              </div>
            )}
            {/* 2 */}
            {photo2 && (
              <div className="col-12 col-lg-7 mt-60 mt-lg-80">
                <figure>
                  <ProjectImage image={photo2.image} />
                </figure>
                <h4 className="mt-24">{photo2.title}</h4>
                <div dangerouslySetInnerHTML={{ __html: photo2.content }} />
              </div>
            )}
          </div>
        </div>
        {/* right */}
        {/* 3 */}
        {photo3 && (
          <div className="col-12 col-lg-4 offset-lg-1 mt-60 mt-lg-0">
            <figure>
              <ProjectImage image={photo3.image} />
            </figure>
            <h4 className="mt-24">{photo3.title}</h4>
            <div dangerouslySetInnerHTML={{ __html: photo3.content }} />
          </div>
        )}
      </div>
    </div>
  )
}

export default Photos
