import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'
import Icon from './icon'

function OpenPosition({ children }) {
  const childs = React.Children.map(children, child =>
    React.cloneElement(child, { ...child.props })
  )

  return <div className="pb-40 border-b mb-40">{childs}</div>
}

OpenPosition.Title = function OpenPositionTitle({ children }) {
  return <h4>{children}</h4>
}

OpenPosition.Description = function OpenPositionDescription({ children }) {
  return <div className="mt-16">{children}</div>
}

OpenPosition.List = function OpenPositionList({ children }) {
  return <ul>{children}</ul>
}

OpenPosition.ListItem = function OpenPositionListItem({ children, link }) {
  return (
    <li className="mt-24">
      <Link to={link} className="h5 link-hover-red">
        {children}
        <Icon className="ml-24" />
      </Link>
    </li>
  )
}

OpenPosition.ListItem.propTypes = {
  children: PropTypes.node,
  link: PropTypes.string
}

export default OpenPosition
