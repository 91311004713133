import React from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'

import './index.css'

function NavigationFilter({ children, filter, onClick }) {
  const childs = React.Children.map(children, child =>
    React.cloneElement(child, { ...child.props, filter, onClick })
  )

  return <ul className="navigation-filter u-sticky">{childs}</ul>
}

NavigationFilter.defaultProps = {
  onClick: () => {}
}

NavigationFilter.propTypes = {
  children: PropTypes.node,
  onClick: PropTypes.func,
  filter: PropTypes.string
}

NavigationFilter.Item = function NavigationFilterItem({
  children,
  onClick,
  filter,
  category
}) {
  return (
    <li className="mb-8">
      <button
        type="button"
        className={cn('navigation-filter-item pb-4', {
          active: filter === category
        })}
        onClick={() => onClick(category)}
      >
        {children}
      </button>
    </li>
  )
}

NavigationFilter.propTypes = {
  children: PropTypes.node,
  onClick: PropTypes.func.isRequired,
  category: PropTypes.string,
  filter: PropTypes.string.isRequired
}

export default NavigationFilter
