import React, { useState } from 'react'

import PageHeader from '@c/page-header'
import OpenPosition from '@c/open-position'
import NavigationFilter from '@c/navigation-filter'
import makeSlug from '@u/make-slug'

const OpenPositions = ({
  titleSmall,
  titleBig,
  description,
  allCategoriesText,
  openPositionCategories,
  culturePageSlug
}) => {
  const [filter, setFilter] = useState(allCategoriesText)
  const onChangeFilter = filter => setFilter(filter)
  const selectedCategories = openPositionCategories.filter(
    cat => filter === allCategoriesText || cat.title === filter
  )

  // console.log({
  //   culturePageSlug,
  //   openPositionCategories
  // })

  return (
    <div className="container mt-60 mt-lg-160 open-positions">
      <div className="row justify-content-center ta-center">
        <div className="col-12 col-lg-8">
          <PageHeader topTitle={titleSmall} title={titleBig} type="h3" />
          <p className="mt-32">{description}</p>
        </div>
      </div>

      <div className="row mt-60 mt-lg-100">
        {/* SIDEBAR */}
        <div className="col-12 col-lg-3 d-none d-lg-block">
          <NavigationFilter filter={filter} onClick={onChangeFilter}>
            {openPositionCategories.map(cat => (
              <NavigationFilter.Item key={cat.id} category={cat.title}>
                {cat.title}{' '}
                {cat.positions && <span>({cat.positions.length})</span>}
              </NavigationFilter.Item>
            ))}
          </NavigationFilter>
        </div>

        {/* BODY */}
        <div className="col-12 col-lg-6">
          {/* Category */}
          {selectedCategories.map(cat => (
            <OpenPosition key={cat.id}>
              <OpenPosition.Title>{cat.title}</OpenPosition.Title>
              <OpenPosition.Description>
                <p>{cat.description}</p>
              </OpenPosition.Description>
              <OpenPosition.List>
                {cat.positions.map(position => (
                  <OpenPosition.ListItem
                    key={position.id}
                    link={makeSlug.generic(
                      position.locale,
                      culturePageSlug,
                      position.slug
                    )}
                  >
                    {position.title}
                  </OpenPosition.ListItem>
                ))}
              </OpenPosition.List>
            </OpenPosition>
          ))}
          {/* Category */}
        </div>
      </div>
    </div>
  )
}

export default OpenPositions
